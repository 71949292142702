




























































import { Vue, Component } from "vue-property-decorator";
import axios from "axios";
import { Activity } from "@/types";

@Component({
  metaInfo() {
    return {
      title: "Faaliyetler / Admin"
    };
  }
})
export default class AdminActivitiesView extends Vue {
  search = "";
  loading = false;
  headers = [
    {
      text: "Id",
      align: "start",
      filterable: true,
      value: "id"
    },
    { text: "Başlık", value: "title" },
    { text: "Puan", value: "points" },
    { text: "Grup Adı", value: "groupTitle"},
    { text: "", value: "actions", sortable: false, width: "50px" }
  ];
  items = [] as Activity[];

  async getItems() {
    try {
      this.loading = true;

      var res = await axios.get("/api/activity/get-all", {
        headers: this.$store.getters["auth/requestAuthHeader"]
      });

      console.log(res.data);

      this.items = res.data;
    } catch (e: unknown) {
      this.$notify({
        type: "error",
        title: "Hata!",
        text: `Faaliyetler alınamadı.`
      });
    } finally {
      this.loading = false;
    }
  }

  async deleteItem(activity: Activity) {
    const confirmation = await this.$confirm("Bu işlem geri alınamaz. Faaliyeti silmek istediğinizden emin misiniz?", { title: "Emin misiniz?" });

    if (!confirmation) return;

    try {
      console.log(activity);

      // Etkinlik grubunu sil
      const authHeader = this.$store.getters["auth/requestAuthHeader"];
      var res = await axios.delete(`/api/activity/${activity.id}`, {
        headers: authHeader
      });

      this.$notify({
        type: "success",
        title: "İşlem Tamam",
        text: "Faaliyet başarıyla silindi."
      });

      await this.getItems();
    } catch (e) {
      this.$notify({
        type: "eror",
        title: "Hata!",
        text: "Faaliyet silinemedi."
      });

      throw e;
    }
  }

  async mounted() {
    await this.getItems();
  }
}
