var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-container',{staticClass:"my-4"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',[_c('v-card-title',[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Ara","single-line":"","hide-details":"","clearable":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","text":"","to":{ name: 'AdminActivityCreate' }}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi-plus-circle")]),_c('span',[_vm._v("YENİ FAALİYET")])],1),_c('v-btn',{attrs:{"icon":""},on:{"click":_vm.getItems}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi-refresh")])],1)],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"search":_vm.search,"loading":_vm.loading,"loading-text":"Yükleniyor..."},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{attrs:{"close-on-content-click":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""}},on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_c('v-list',{attrs:{"dense":""}},[_c('v-list-item',{attrs:{"to":{
                      name: 'AdminActivityEdit',
                      params: { id: item.id }
                    }}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-file-edit")])],1),_c('v-list-item-title',[_vm._v("Düzenle")])],1),_c('v-list-item',{on:{"click":function($event){return _vm.deleteItem(item)}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-delete")])],1),_c('v-list-item-title',[_vm._v("Sil")])],1)],1)],1)]}}],null,true)})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }